import * as React from "react"
import {
    Flex,
    Box,
    Text,
    Breadcrumb,
    BreadcrumbLink,
    BreadcrumbItem,
    useToast,
    HStack,
    VStack,
    Heading,
    List,
    ListItem,
    Switch,
    Button, FormLabel, Input, FormErrorMessage, FormControl, Select, Textarea, Spacer,
} from "@chakra-ui/react"
import {Link, useHistory, useParams, useRouteMatch} from "react-router-dom"
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useEffect, useState} from "react";
import {AxiosResponse} from "axios";
import {Answer, Question} from "../../types/QuestionnaireTypes";
import {addQuestion, getQuestion, updateQuestion} from "../../api/QuestionService";
import {AddIcon} from "@chakra-ui/icons";

const initialSchema = yup.object().shape({
    key: yup.string().min(1).required(),
    text: yup.string().max(100).required(),
    answers: yup.array().of(yup.object().shape({
        key: yup.string().min(1).required(),
        text: yup.string().max(100).required()
    })).required()
});

export function QuestionDetails() {
    const toast = useToast();
    let { questionId } = useParams();
    let match = useRouteMatch();
    let history = useHistory();
    const [type, setType] = useState("");

    const [validationSchema, setValidationSchema] = useState(yup.object().shape({
        key: yup.string().min(1).required(),
        text: yup.string().max(100).required(),
        answers: yup.array().of(yup.object().shape({
            key: yup.string().min(1).required(),
            text: yup.string().max(100).required()
        })).required()
    }));

    const { register, formState: { errors }, handleSubmit, setValue, trigger } = useForm<Question>({
        mode: "onBlur",
        resolver: yupResolver(validationSchema),
    });

    function getUpdatedSchema(questionType: string): yup.ObjectSchema<any> {
        if (["SINGLE_CHOICE_NUMERIC"].includes(type as string)) {
            return initialSchema.shape({
                answers: yup.array().of(yup.object().shape({
                    key: yup.string().min(1).required(),
                    text: yup.string().matches(/^\d+$/, "Answer field must be numeric").required()
                })).required()
            }) as yup.ObjectSchema<any>;
        } else {
            return initialSchema.shape({
                answers: yup.array().notRequired()
            }) as yup.ObjectSchema<any>;
        } 
    }

    useEffect(() => {
        const updatedSchema = getUpdatedSchema(type);
        setValidationSchema(updatedSchema);
    }, [type]);

    let initial : Answer[] = [{
        key: "",
        text: "",
        active: true
    },{
        key: "",
        text: "",
        active: true
    },{
        key: "",
        text: "",
        active: true
    },]

    const [key, setKey] = useState("");
    const [text, setText] = useState("");
    const [status, setStatus] = useState(true);
    const [answers, setAnswers] = useState(initial);

    const editActiveAnswer = (i: number) => {
        let temp = answers
        temp[i].active = !temp[i].active
        setAnswers([...temp])
    };

    const editKeyAnswer = (i: number, value: string) => {
        let temp = answers
        temp[i].key = value
        setAnswers([...temp])
    };
    const editTextAnswer = (i: number, value: string) => {
        let temp = answers
        temp[i].text = value
        setAnswers([...temp])
    };

    const addNewAnswer = () => {
        let temp = answers
        let answer: Answer = {
            active: true,
            key: "",
            text: ""
        }
        temp.push(answer)
        setAnswers([...temp])
    };

    const saveAction = async () => {    
        const isFormValid = await trigger();
    
        if (isFormValid) {
            let question: Question = {
                key: key,
                type: type,
                text: text,
                active: status,
                answers: ["TEXT_INPUT", "MULTILINE_TEXT", "HIDDEN"].includes(type) ? [] : answers
            };
    
            if (!questionId) {
                createQuestion(question);
            } else {
                modifyQuestion(question);
            }
        } else {
            toast({
                title: "Validation Error",
                description: "Please correct the errors before saving.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };    

    const createQuestion = (question : Question) => {
        addQuestion(question).then((response: AxiosResponse) => {
            if ([200, 201].includes(response.status)) {
                toast({
                    title: "Question",
                    position: "top",
                    description: "Question and Answers added successfully",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                })
                history.push('/questionnaires');
            } else {
                return Promise.reject(response.data.apierror.message);
            }
        }).catch( (error: any) => {
            toast({
                title: "Failed to add Question",
                position: "bottom",
                description: JSON.stringify(error?.response?.data?.apierror ? error.response.data.apierror.debugMessage : error?.message),
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        })
    };

    const modifyQuestion = (question : Question) => {
        updateQuestion(question).then((response: AxiosResponse) => {
            if ([200, 201].includes(response.status)) {
                toast({
                    title: "Question",
                    position: "top",
                    description: "Question and Answers updated successfully",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                })
                history.push('/questionnaires');
            } else {
                return Promise.reject(response.data.apierror.message);
            }
        }).catch( (error: any) => {
            toast({
                title: "Failed to update Question",
                position: "bottom",
                description: JSON.stringify(error?.response?.data?.apierror ? error.response.data.apierror.debugMessage : error?.message),
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        })
    };

    useEffect(() => {
        if (questionId) {
            let params = {
                key: questionId
            }
            getQuestion(params).then((response: AxiosResponse) => {
                if ([200, 201].includes(response.status)) {
                    let question: Question = response.data
                    setKey(question.key as string);
                    setType(question.type as string);
                    setText(question.text as string);
                    setStatus(question.active as boolean);
                    if (!["TEXT_INPUT", "MULTILINE_TEXT", "HIDDEN"].includes(question.type as string)) {    
                        setAnswers(question.answers as Answer[]);
                    }
                } else {
                    return Promise.reject(response.data.apierror.message);
                }
            }).catch( (error: any) => {
                toast({
                    title: "Failed to get Question from backend",
                    position: "bottom",
                    description: JSON.stringify(error?.response?.data?.apierror ? error.response.data.apierror.debugMessage : error?.message),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
                history.push('/questionnaires');
            })
        }
    }, [history, questionId, toast]);

    useEffect(() => {
        setValue("type", type);
    }, [type, setValue]);

    return (
      <Box w="100%" h="90%" bg="background.secondary">
          <Flex w="100%" h="10%" pl={6} alignItems="center">
              <Breadcrumb color="gostation.500" fontWeight="bold" fontSize="lg">
                  <BreadcrumbItem color="gostation.100">
                      <BreadcrumbLink as={Link} to="/questionnaires">
                          <Text>Questionnaires</Text>
                      </BreadcrumbLink>
                  </BreadcrumbItem>
                  <BreadcrumbItem color="gostation.500" isCurrentPage>
                      <BreadcrumbLink as={Link} to={match.url}>
                          {questionId ? <Text>Edit Question</Text> : <Text>New Question</Text>}
                      </BreadcrumbLink>
                  </BreadcrumbItem>
              </Breadcrumb>
          </Flex>
          <form onSubmit={handleSubmit(saveAction)}>
            <Flex w="96%" minH="500px" ml={6} mr={6} bg="white">
                <HStack>
                    <VStack minW="550px" minH="500px" pl={4} pr={4} alignItems="left">
                        <Heading pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.500">Question Definition</Heading>
                        <FormControl id="status" w="315px" p="1">
                            <FormLabel fontSize="xs" color="gray">Question Status</FormLabel>
                            <Switch colorScheme="primary" size="md" isChecked={status} onChange={() => setStatus(!status)}/>
                        </FormControl>
                        <FormControl id="type" w="315px" p="1">
                            <FormLabel fontSize="xs" color="gray">Select Question Type</FormLabel>
                            <Select isRequired placeholder="Please Select" value={type} onChange={(e) => setType(e.target.value)}>
                                <option value="TEXT_INPUT">Free Text</option>
                                <option value="SINGLE_CHOICE">Single Choice</option>
                                <option value="MULTIPLE_CHOICE">Multiple Choice</option>
                                <option value="MULTILINE_TEXT">Multiline Text</option>
                                <option value="SINGLE_CHOICE_NUMERIC">Numeric Single Choice</option>
                                <option value="HIDDEN">Hidden</option>
                            </Select>
                        </FormControl>
                        <FormControl id="key" w="315px" p="1"
                                    isInvalid={!!errors?.key?.message}
                                    errortext={errors?.key?.message}>
                            <FormLabel fontSize="xs" color="gray">Question Key</FormLabel>
                            <Input {...register("key")} isRequired isDisabled={questionId} placeholder="Type unique key" value={key} size="md" onChange={(e) => setKey(e.target.value)}  />
                            <FormErrorMessage>{errors?.key?.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl id="text" w="500px" p="1"
                                    isInvalid={!!errors?.text?.message}
                                    errortext={errors?.text?.message}>
                            <FormLabel fontSize="xs" color="gray">Question Text</FormLabel>
                            <Textarea {...register("text")} isRequired placeholder="Type the question here" value={text} size="md" onChange={(e) => setText(e.target.value)}  />
                            <FormErrorMessage>{errors?.text?.message}</FormErrorMessage>
                        </FormControl>
                        <Spacer/>
                        <HStack spacing={6} p={4}>
                            <Button as={Link} to="/questionnaires" colorScheme="gostation" variant="outline" size="md">CANCEL</Button>
                            <Button onClick={() => {saveAction()}} mr="auto" colorScheme="gostation" variant="solid" size="md">PUBLISH</Button>
                        </HStack>
                    </VStack>
                    {(type === "SINGLE_CHOICE" || type === "MULTIPLE_CHOICE" || type === "SINGLE_CHOICE_NUMERIC") && <VStack minW="600px" minH="500px" pl={4} pr={4} alignItems="left">
                        <Heading pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.500">Answers</Heading>
                        <List spacing={3} variant="simple" size="sm">
                            {answers && answers.map((answer : Answer, i: number) => {
                                return (
                                    <ListItem p={1} key={i}>
                                        <HStack>
                                            <FormControl id={"answers[" + i + "}].status"} minW="90px">
                                                <FormLabel fontSize="xs" color="gray">Answer Status</FormLabel>
                                                <Switch colorScheme="primary" size="md" isChecked={answer.active} onChange={() => editActiveAnswer(i)}/>
                                            </FormControl>
                                            <FormControl id={"answers[" + i + "}].key"} minW="90px"
                                                        isInvalid={!!errors?.answers?.[i]?.key?.message}
                                                        errortext={errors?.answers?.[i]?.key?.message}>
                                                <FormLabel fontSize="xs" color="gray">Answer Key</FormLabel>
                                                <Input {...register(`answers[${i}].key`)} isRequired placeholder="Type key" value={answer.key} size="xs" onChange={(e) => editKeyAnswer(i, e.target.value)}  />
                                                <FormErrorMessage>{errors?.answers?.[i]?.key?.message}</FormErrorMessage>
                                            </FormControl>
                                            <FormControl id={"answers[" + i + "}].text"} minW="320px"
                                                        isInvalid={!!errors?.answers?.[i]?.text?.message}
                                                        errortext={errors?.answers?.[i]?.text?.message}>
                                                <FormLabel fontSize="xs" color="gray">Answer</FormLabel>
                                                <Input {...register(`answers[${i}].text`)} isRequired placeholder="Type answer here" value={answer.text} size="xs" onChange={(e) => editTextAnswer(i, e.target.value)}  />
                                                <FormErrorMessage>{errors?.answers?.[i]?.text?.message}</FormErrorMessage>
                                            </FormControl>
                                        </HStack>
                                    </ListItem>)
                            })}
                        </List>
                        <HStack p={3}>
                            <Button onClick={() => {addNewAnswer()}} leftIcon={<AddIcon />} colorScheme="primary" variant="solid" size="md">NEW ANSWER</Button>
                            <Spacer/>
                        </HStack>
                    </VStack>}
                </HStack>
            </Flex>
          </form>
      </Box>
  );
}
