import * as React from "react"
import {
    Flex,
    Box,
    Text,
    Breadcrumb,
    BreadcrumbLink,
    BreadcrumbItem,
    HStack,
    VStack,
    Button,
    Divider,
    useToast,
    Center,
    Heading,
    Select,
    Input,
    Textarea,
    InputGroup,
    InputRightElement,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    RadioGroup,
    Radio,
    Stack,
    Checkbox,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Spacer,
    ModalFooter,
    useDisclosure,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    IconButton,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Icon,
    FormErrorMessage,
    FormControl,
    AlertDialog, AlertDialogBody, AlertDialogOverlay, AlertDialogFooter, AlertDialogContent, AlertDialogHeader
} from "@chakra-ui/react"
import { Link, useParams, useRouteMatch, useHistory } from "react-router-dom"
import {PromoCode, UserAllowedPromoCode} from "../../types/PromoCodeTypes";
import {useEffect, useState} from "react";
import {getPromoCode, addPromoCode, updatePromoCode} from "../../api/PromoCodeService";
import {AxiosResponse} from "axios";
import DatePicker from "react-datepicker";
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {changeSize, initialDescriptionShort, isNextPage, isPrevPage, nextPage, prevPage} from "../../utils/pagination";
import {User} from "../../types/UserTypes";
import { debounce } from 'lodash';
import userSearchByPattern from "../../helpers/userSearchHelper";
import {BiFilter} from "react-icons/bi";
import "../../css/react-datepicker.css";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {MdDelete, MdFileUpload} from "react-icons/all";
import {CSVReader} from "react-papaparse";
import {ParseResult} from "papaparse";
import CustomTable from "../../components/CustomTable";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../store/index";
import Loader from '../../utils/Loading';
import { PageRequest } from "../../types/ApiTypes";

export function PromoCodesDetails() {
    const toast = useToast();
    let { promoId } = useParams();

    const createSchema = yup.object().shape({
        code: yup.string().min(1).max(20).required(),
        text: yup.string().max(100).required()
    });

    const editSchema = yup.object().shape({
        code: yup.string().min(1).max(20),
        text: yup.string().max(100)
    });

    const schema = promoId ? editSchema : createSchema;

    let match = useRouteMatch();
    let history = useHistory();
    const { isOpen, onOpen, onClose } = useDisclosure()
    let initial : UserAllowedPromoCode[] = []
    let initialOriginal : UserAllowedPromoCode[] = []
    const {
        isOpen: isOpenList,
        onOpen: onOpenList,
        onClose: onCloseList
    } = useDisclosure()

    const [isOpenConfirm, setIsOpenConfirm] = React.useState(false)
    const onCloseConfirm = () => setIsOpenConfirm(false)
    const cancelRef = React.useRef()

    const { register, trigger, formState: { errors, isValid }, } = useForm<PromoCode>({
        mode: "onBlur",
        resolver: yupResolver(schema),
    });

    const [code, setCode] = useState("");
    const [isLoadingPromoCode, setIsLoadingPromoCode] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [value, setValue] = useState(1);
    const [valueType, setValueType] = useState("MONEY");
    const [type, setType] = useState("");
    const [isTypeInvalid, setIsTypeInvalid] = useState(false);
    const [text, setText] = useState("");
    const [status, setStatus] = useState(false);
    const [spendLimit, setSpendLimit] = useState(0);
    const [totalSpent, setTotalSpent] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [expireDate, setExpireDate] = useState(new Date());
    const [isUserSpecific, setIsUserSpecific] = useState(false);
    const [usersAllowed, setUsersAllowed] = useState(initial);
    const [usersAllowedOriginal, setUsersAllowedOriginal] = useState(initialOriginal);
    const [expires, setExpires] = useState(false);
    const [lat, setLat] = useState(35.098702);
    const [long, setLong] = useState(-106.564627);
    const [radius, setRadius] = useState(0);
    const [evse, setEvse] = useState("");
    const [networkId, setNetworkId] = useState("");
    const [locationId, setLocationId] = useState("");
    const [locationRestriction, setLocationRestriction] = useState("NO_LOCATION_RESTRICTION");

    let initialUsers : User[] = []
    let initialSelectedUsers : User[] = []
    let initialCSVUsers : User[] = []
    const [usersList, setUsersList] = React.useState(initialUsers)
    const [selectedUsersList, setSelectedUsersList] = React.useState(initialSelectedUsers)

    const [pageRequest, setPageRequest] = React.useState(initialDescriptionShort)
    const [totalHits, setTotalHits] = React.useState(0)

    const [csvFile, setCsvFile] = React.useState(initialCSVUsers)
    const [isImportDisabled, setIsImportDisabled] = React.useState(true)

    const moneyFormat = (val: number) => isNaN(val) ? `$0` : `$` + val.toFixed(2)
    const moneyParse = (val: string) => parseFloat(val.replace(/^\$/, ""))

    const percentageFormat = (val: number) => isNaN(val) ? `%0` : `%` + val;
    const percentageParse = (val: string) => parseInt(val.replace(/^%/, ""))

    const handleTypeChange = (e) => {
        const selectedType = e.target.value;
        setType(selectedType);

        // Check if the selected type is empty
        setIsTypeInvalid(selectedType === '');
    };

    const saveAction = async (send: boolean) => {
        setIsTypeInvalid(type === '');
        await trigger();
        if (!isValid || !type || isSaving) {
          return;
        }

        if (!promoId && !isUserSpecific && send) {
          setIsOpenConfirm(true);
        } else {
          saveActionInternal(send);
        }
    };

    const confirmAction = () => {
        saveActionInternal(true)
        onCloseConfirm()
    };

    const saveActionInternal = (send: boolean) => {
        let promoCode : PromoCode = {
            code : code,
            value : value,
            valueType : valueType,
            type : type,
            text : text,
            status : status,
            spendLimit : spendLimit,
            totalSpent : totalSpent,
            startDate : startDate.toISOString(),
            expireDate : expires ? expireDate.toISOString() : "",
            isUserSpecific : isUserSpecific,
            usersAllowed : isUserSpecific ? usersAllowed : [],
            isLocationRestricted : locationRestriction !== "NO_LOCATION_RESTRICTION",
        }
        const { ...rest } = promoCode
        if (evse) {
            promoCode = {
                ...rest,
                physicalReference : evse
            }
        } else if (radius > 0) {
            promoCode = {
                ...rest,
                latitude : lat,
                longitude : long,
                radius : radius
            }
        } else {
            promoCode = {
                ...rest,
                locationId : locationId,
                networkId : networkId
            }
        }

        if (!promoId) {
            addPromo(promoCode, send)
        } else {
            updatePromo(promoCode, send)
        }
    };

    const resetData = () => {
        let dataCopy : UserAllowedPromoCode[] = [...usersAllowedOriginal];
        setUsersAllowed(dataCopy)
    };

    const addUserList = (checked: boolean, user: User) => {
        if (checked) {
            let temp = selectedUsersList
            temp.push(user)
            setSelectedUsersList(temp)
        } else {
            let newList = selectedUsersList.filter((item: User) => item.username !== user.username);
            setSelectedUsersList(newList)
        }
    };

    const addUserCSV = () => {
        addUserAllowedInternal(csvFile)
        setCsvFile([])
        onCloseList()
    };

    const addUserAllowed = () => {
        addUserAllowedInternal(selectedUsersList)
        setSelectedUsersList([])
        setCurrentFilter("")
        onClose();
    };

    const addUserAllowedInternal = (usersInternal: User[]) => {
        let newList
        if (usersAllowed.length > 0) {
            newList = usersInternal.filter((item: User) => !usersAllowed?.some(user=> item.username === user.username));
        } else {
            newList = usersInternal
        }
        let temp = usersAllowed
        newList.forEach((item: User) => {
            let userAllowed: UserAllowedPromoCode = {
                username: item.username,
                firstName: item?.profile?.firstName,
                lastName: item?.profile?.lastName,
                code: code,
                userAssigned: false,
                userUsed: false
            }
            temp.push(userAllowed);
        })
        setUsersAllowed(temp);
    };

    const [currentFilter, setCurrentFilter] = useState("");
    const dispatch = useDispatch();
    const isLoading = useSelector((state: RootState) => state.loading.isLoading);
    const isFirstOpenRef = React.useRef(true);
    const previousFilterRef = React.useRef<string | undefined>(undefined);
    const previousPageRequestRef = React.useRef<PageRequest | undefined>(undefined);

    const searchUser = React.useCallback((pattern: string) => {
        if ((pattern !== "") && (currentFilter !== pattern)) {
            setPageRequest(prevState => ({ ...prevState, page: 0 }));
        } else {
            setCurrentFilter(pattern);
        }
        dispatch(userSearchByPattern(pattern, pageRequest, setTotalHits, setUsersList, toast));
    }, [dispatch, pageRequest, setTotalHits, setUsersList, toast]);

    const debouncedSearchUser = React.useCallback(
        debounce((pattern: string) => {
            searchUser(pattern);
        }, 500),
        [searchUser]
    );

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        debouncedSearchUser(e.target.value);
    };

    useEffect(() => {
        if (isOpen) {
            if (isFirstOpenRef.current || currentFilter !== previousFilterRef.current || pageRequest.page !== previousPageRequestRef.current?.page ) {
                debouncedSearchUser(currentFilter);
            }
            isFirstOpenRef.current = false;
        }
        previousFilterRef.current = currentFilter;
        previousPageRequestRef.current = pageRequest;
        return () => {
            debouncedSearchUser.cancel();
        };
    }, [currentFilter, debouncedSearchUser, isOpen]);

    const addPromo = (promoCode : PromoCode, send: boolean) => {
        let params = {
            send: send
        }
        setIsSaving(true);
        addPromoCode(promoCode, params).then((response: AxiosResponse) => {
            if ([200, 201].includes(response.status)) {
                toast({
                    title: "Promo Code",
                    position: "top",
                    description: "Promo code added successfully",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                })
                history.push('/promoCodes');
            } else {
                return Promise.reject(response.data.apierror.message);
            }
        }).catch( (error: any) => {
            toast({
                title: "Failed to add Promo Code",
                position: "bottom",
                description: JSON.stringify(error?.response?.data?.apierror ? error.response.data.apierror.debugMessage : error?.message),
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        }).finally(() => {
            setIsSaving(false);
        });
    };

    const updatePromo = (promoCode : PromoCode, send: boolean) => {
        let params = {
            send: send
        }
        setIsSaving(true);
        updatePromoCode(promoCode, params).then((response: AxiosResponse) => {
            if ([200, 201].includes(response.status)) {
                toast({
                    title: "Promo Code",
                    position: "top",
                    description: "Promo code updated successfully",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                })
                history.push('/promoCodes');
            } else {
                return Promise.reject(response.data.apierror.message);
            }
        }).catch( (error: any) => {
            toast({
                title: "Failed to update Promo Code",
                position: "bottom",
                description: JSON.stringify(error?.response?.data?.apierror ? error.response.data.apierror.debugMessage : error?.message),
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        }).finally(() => {
          setIsSaving(false);
        });
    };

    useEffect(() => {
        setIsLoadingPromoCode(false);
        if (promoId) {
            let params = {
                code: promoId
            }
            setIsLoadingPromoCode(true);
            getPromoCode(params).then((response: AxiosResponse) => {
                setIsLoadingPromoCode(false);
                if ([200, 201].includes(response.status)) {
                    let promo: PromoCode = response.data
                    setCode(promo.code as string);
                    setValue(promo.value as number);
                    setValueType(promo.valueType as string);
                    setType(promo.type as string);
                    setText(promo.text as string);
                    setStatus(promo.status as boolean);
                    setSpendLimit(promo.spendLimit as number);
                    setTotalSpent(promo.totalSpent as number);
                    setStartDate(new Date(promo.startDate as string));
                    if (promo.expireDate) {
                        setExpires(true);
                        setExpireDate(new Date(promo.expireDate as string));
                    } else {
                        setExpires(false);
                        setExpireDate(new Date());
                    }
                    setIsUserSpecific(promo.isUserSpecific as boolean);
                    setUsersAllowed(promo.usersAllowed as UserAllowedPromoCode[]);
                    let dataCopy : UserAllowedPromoCode[] = [...promo.usersAllowed as UserAllowedPromoCode[]];
                    setUsersAllowedOriginal(dataCopy);
                    if (promo.isLocationRestricted) {
                        if (promo.radius && promo.radius > 0) {
                            setLat(promo.latitude as number);
                            setLong(promo.longitude as number);
                            setRadius(promo.radius as number);
                            setLocationRestriction("AREA_LOCATION_RESTRICTION");
                        } else {
                            setNetworkId(promo.networkId as string);
                            setLocationId(promo.locationId as string);
                            setLocationRestriction("LOCATION_RESTRICTION");
                        }
                    } else {
                        setLocationRestriction("NO_LOCATION_RESTRICTION");
                    }
                } else {
                    return Promise.reject(response.data.apierror.message);
                }
            }).catch( (error: any) => {
                setIsLoadingPromoCode(false);
                toast({
                    title: "Failed to get Promo Codes from backend",
                    position: "bottom",
                    description: JSON.stringify(error?.response?.data?.apierror ? error.response.data.apierror.debugMessage : error?.message),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
                history.push('/promoCodes');
            })
        }
    }, [history, promoId, toast]);

    const handleOnDrop = (data: Array<ParseResult<any>>) => {
        let body : User[] = []
        data.forEach((row: ParseResult<any>) => {
            //handle parsing errors
            if (!row.errors || !(row.errors.length > 0)) {
                //skip bad rows
                if (!row.meta.aborted && !row.meta.truncated) {
                    let rowData : string[] = row.data
                    //skip headers
                    if (rowData[0]!=='Username') {
                        let user: User = {
                            username: rowData[0],
                            email: rowData[1]
                        }
                        body.push(user);
                    }
                }
            }
        })
        setIsImportDisabled(false)
        setCsvFile(body)
    };

    const handleOnError = (err: any, file: any, inputElem: any, reason: any) => {
        setIsImportDisabled(true)
        setCsvFile([])
    };

    const handleOnRemoveFile = (data: any) => {
        setIsImportDisabled(true)
        setCsvFile([])
    };

    const columnsData = [
        {
            Header: "USERNAME",
            accessor: "username"
        },
        {
            Header: "FIRST NAME",
            accessor: "firstName"
        },
        {
            Header: "LAST NAME",
            accessor: "lastName"
        },
        {
            Header: "ASSIGNED",
            accessor: (user: UserAllowedPromoCode) => user.userAssigned ? "Yes" : "No"
        },
        {
            Header: "USED",
            accessor: (user: UserAllowedPromoCode) => user.userUsed ? "Yes" : "No"
        },
        {
            Header: 'Delete',
            id: 'delete',
            accessor: (user: UserAllowedPromoCode) => 'delete',

            Cell: (tableProps: any) => (
                <IconButton
                    variant='outline'
                    colorScheme='gostation'
                    aria-label='Delete'
                    icon={<MdDelete/>}
                    size="2xs"
                    isDisabled={usersAllowed[tableProps.row.index].userAssigned}
                    onClick={() => {
                      let dataCopy : UserAllowedPromoCode[] = [...usersAllowed];
                      if (!dataCopy[tableProps.row.index].userAssigned) {
                          dataCopy.splice(tableProps.row.index, 1);
                          setUsersAllowed(dataCopy);
                      } else {
                          toast({
                              title: "Cannot remove row from User list",
                              position: "bottom",
                              description: "User already has the Promo Code assigned",
                              status: "warning",
                              duration: 2000,
                              isClosable: true,
                          })
                      }

                    }}
                />
            ),
        },
    ];

    return (
      <Box w="100%" h="90%" bg="background.secondary">
          <Flex w="100%" h="10%" pl={6} alignItems="center">
              <Breadcrumb color="gostation.500" fontWeight="bold" fontSize="lg">
                  <BreadcrumbItem color="gostation.100">
                      <BreadcrumbLink as={Link} to="/promoCodes">
                          <Text>Promo Codes</Text>
                      </BreadcrumbLink>
                  </BreadcrumbItem>
                  <BreadcrumbItem color="gostation.500" isCurrentPage>
                      <BreadcrumbLink as={Link} to={match.url}>
                          {promoId ? <Text>{promoId}</Text> : <Text>New Promo Code</Text>}
                      </BreadcrumbLink>
                  </BreadcrumbItem>
              </Breadcrumb>
          </Flex>
          {isLoadingPromoCode ? (
                <Loader />
          ) : (
          <Flex w="96%" h="90%" minH="500px" bg="white" ml={6} mr={6}>
              <VStack>
                  <HStack>
                      <VStack minH="550px" pl={4} pr={4} alignItems="left">
                          <Heading pt={2} fontWeight="bold" size="sm" color="gostation.500">Name and Description</Heading>
                          <HStack spacing={4}>
                              <Text fontSize="xs" color="gray">Promo Code</Text>
                              <Text pl={24} fontStyle="italic" fontSize="xs" color="gray">20 characters</Text>
                              <Text pl={40} fontSize="xs" color="gray">Promo Type</Text>
                          </HStack>
                          <HStack>
                              <FormControl
                                           isInvalid={!!errors?.code?.message}
                                           errortext={errors?.code?.message}>
                                  <Input {...register("code")} isRequired isDisabled={promoId} placeholder="CODE1234" value={code} size="md" onChange={(e) => setCode(e.target.value.toUpperCase())}  />
                                  <FormErrorMessage>{errors?.code?.message}</FormErrorMessage>
                              </FormControl>
                              <FormControl
                                isRequired
                                isInvalid={isTypeInvalid}
                            >
                                <Select
                                    isRequired
                                    placeholder="Please Select "
                                    value={type}
                                    onChange={handleTypeChange}
                                    >
                                    <option value="LOYALTY">Loyalty</option>
                                    <option value="WELCOME">Welcome</option>
                                    <option value="ENGAGEMENT">Engagement</option>
                                </Select>
                                <FormErrorMessage>promo type is required</FormErrorMessage>
                            </FormControl>
                          </HStack>
                          <HStack spacing={60}>
                              <Text fontSize="xs" color="gray">Promo Code Text</Text>
                              <Text pl={12} fontStyle="italic" fontSize="xs" color="gray">max. 100 characters</Text>
                          </HStack>
                          <FormControl
                              isInvalid={!!errors?.text?.message}
                              errortext={errors?.text?.message}>
                              <Textarea {...register("text")} value={text} placeholder="Type text here..." size="md" onChange={(e) => setText(e.target.value)}/>
                              <FormErrorMessage>{errors?.text?.message}</FormErrorMessage>
                          </FormControl>
                          <Heading fontWeight="bold" size="sm" color="gostation.500" textAlign="left">Campaign Value</Heading>
                          <HStack spacing={60}>
                              <Text pr={20} fontSize="xs" color="gray">Promo Code Value</Text>
                              <Text pl={12} fontSize="xs" color="gray">Value</Text>
                          </HStack>
                          <HStack spacing={10}>
                              <RadioGroup value={valueType} onChange={(v) => setValueType(v)}>
                                  <Stack spacing={4} direction="row">
                                      <Radio isDisabled={promoId} value="MONEY">Money($)</Radio>
                                      <Radio isDisabled={promoId} value="PERCENTAGE">Percentage(%)</Radio>
                                  </Stack>
                              </RadioGroup>
                              <InputGroup>
                                  <NumberInput ml="auto" min={1} value={valueType === "MONEY" ? moneyFormat(value) : percentageFormat(value)}
                                               onChange={(valueAsString) => setValue(valueType === "MONEY" ? moneyParse(valueAsString) : percentageParse(valueAsString))}>
                                      <NumberInputField />
                                      <NumberInputStepper>
                                          <NumberIncrementStepper />
                                          <NumberDecrementStepper />
                                      </NumberInputStepper>
                                  </NumberInput>
                              </InputGroup>
                          </HStack>
                          <Heading fontWeight="bold" size="sm" color="gostation.500" textAlign="left">Campaign Duration</Heading>
                          <Text fontSize="xs" color="gray">Start Date</Text>
                          <DatePicker className="react-datepicker" dateFormat="MM/dd/yyyy h:mm aa" showTimeSelect selected={startDate} onChange={(date: Date) => setStartDate(date)} />
                          <Text fontSize="xs" color="gray">Does this offer expires?</Text>
                          <HStack>
                              <Checkbox isChecked={expires} onChange={(e) => setExpires(e.target.checked)}>Check if this offer expires</Checkbox>
                              {expires && <DatePicker className="react-datepicker" dateFormat="MM/dd/yyyy h:mm aa" showTimeSelect selected={expireDate} onChange={(date: Date) => setExpireDate(date)} />}
                          </HStack>
                          <Heading fontWeight="bold" size="sm" color="gostation.500" textAlign="left">Campaign Spend Limit</Heading>
                          <Text fontSize="xs" color="gray">Specify a spend limit</Text>
                          <NumberInput min={0} value={moneyFormat(spendLimit)} onChange={(valueAsString) => setSpendLimit(moneyParse(valueAsString))}>
                              <NumberInputField />
                              <NumberInputStepper>
                                  <NumberIncrementStepper />
                                  <NumberDecrementStepper />
                              </NumberInputStepper>
                          </NumberInput>
                          <Text fontSize="xs" color="gray">Limit spent</Text>
                          <NumberInput min={0} isDisabled={true} value={moneyFormat(totalSpent)}>
                              <NumberInputField />
                          </NumberInput>
                      </VStack>
                      <Center h="550px">
                          <Divider orientation="vertical" />
                      </Center>
                      <VStack minH="550px" minW="513px" pl={4} pr={4} alignItems="left">
                          <Heading pt={2} fontWeight="bold" size="sm" color="gostation.500" textAlign="left">Promo Code Status</Heading>
                          <Text fontSize="xs" color="gray">Status</Text>
                          <HStack spacing={5}>
                              {status ? <Center p={2} fontSize="sm" fontWeight="bold" borderRadius="10px" maxW="120px" borderWidth={2} borderColor="primary.500" color="primary.500">ACTIVE</Center>
                              : <Center p={2} fontSize="sm" fontWeight="bold" borderRadius="10px" maxW="120px" borderWidth={2} borderColor="#76777A" color="#76777A">INACTIVE</Center>}
                              <Button onClick={() => {setStatus(!status)}} mr="auto" colorScheme="gostation" bg="gostation.100" variant="solid" size="md">CHANGE STATUS</Button>
                          </HStack>
                          <Heading fontWeight="bold" size="sm" color="gostation.500" textAlign="left">Promo Code Location Restrictions</Heading>
                          <HStack spacing={10}>
                              <Text fontSize="xs" color="gray">Criteria</Text>
                          </HStack>
                          <VStack alignItems="start">
                              <RadioGroup onChange={(nextValue : string) => setLocationRestriction(nextValue)} value={locationRestriction}>
                                  <Radio pl={2} size='sm' colorScheme='gostation' value="NO_LOCATION_RESTRICTION" defaultChecked>
                                      No Location restriction
                                  </Radio>
                                  <Radio pl={2} size='sm' colorScheme='gostation' value="LOCATION_RESTRICTION">
                                      Location specific
                                  </Radio>
                                  <Radio pl={2} size='sm' colorScheme='gostation' value="AREA_LOCATION_RESTRICTION">
                                      Area restriction for Location
                                  </Radio>
                              </RadioGroup>
                              {(locationRestriction === "LOCATION_RESTRICTION") && <Box>
                                  <HStack spacing={"90px"} mt={2}>
                                      <Text fontSize="xs" color="gray">Station Physical Reference</Text>
                                      {networkId && locationId && <Text fontSize="xs" color="gray">Network Id</Text>}
                                      {networkId && locationId && <Text fontSize="xs" color="gray">Location Id</Text>}
                                  </HStack>
                                  <HStack spacing={5} mt={"0.5rem"}>
                                      <Input placeholder="e.g. GODC0001" value={evse} size="md" onChange={(e) => setEvse(e.target.value)}/>
                                      {networkId && locationId && <Input isDisabled value={networkId} size="md" maxW="130px" />}
                                      {networkId && locationId && <Input isDisabled value={locationId} size="md"/>}
                                  </HStack>
                              </Box>}
                              {(locationRestriction === "AREA_LOCATION_RESTRICTION") && <Box>
                                  <HStack spacing={"150px"} mt={2}>
                                      <Text fontSize="xs" color="gray">Location Latitude</Text>
                                      <Text fontSize="xs" color="gray">Location Longitude</Text>
                                      <Text fontSize="xs" color="gray">Location Distance</Text>
                                  </HStack>
                                  <HStack spacing={5} mt={2}>
                                      <NumberInput value={lat} precision={6} step={0.2}
                                                   onChange={(valueAsString, valueAsNumber) => setLat(valueAsNumber)}>
                                          <NumberInputField />
                                      </NumberInput>
                                      <NumberInput value={long} precision={6} step={0.2}
                                                   onChange={(valueAsString, valueAsNumber) => setLong(valueAsNumber)}>
                                          <NumberInputField />
                                      </NumberInput>
                                      <NumberInput value={radius} min={0} maxW="100px"
                                                   onChange={(valueAsString, valueAsNumber) => setRadius(valueAsNumber)}>
                                          <NumberInputField />
                                      </NumberInput>
                                  </HStack>
                              </Box>}
                          </VStack>
                          <Heading fontWeight="bold" size="sm" color="gostation.500" textAlign="left">Promo Code Assigned to</Heading>
                          <HStack spacing={10}>
                              <Text fontSize="xs" color="gray">Criteria</Text>
                              {isUserSpecific && <Text pl={20} fontSize="xs" color="gray">Specify Users</Text>}
                          </HStack>
                          <HStack spacing={5}>
                              <Checkbox isDisabled={promoId} isChecked={isUserSpecific} onChange={(e) => setIsUserSpecific(e.target.checked)}>Is User Specific</Checkbox>
                              {isUserSpecific && <Button onClick={onOpen} colorScheme="gostation" bg="gostation.100" variant="solid" size="md">SELECT USER/S</Button>}
                              <Modal isCentered isOpen={isOpen} onClose={onClose} motionPreset="none" size="lg">
                                  <ModalOverlay />
                                  <ModalContent minH="450px" width="fit-content" minW="950px">
                                      <ModalHeader color="gostation.500">Select Users allowed to use Promo Code</ModalHeader>
                                      <ModalCloseButton />
                                      <ModalBody>
                                          <Flex h="8%" ml={6} pl={4} pt={2} pb={2} mr={6} pr={4} alignItems="center" bg="white">
                                              <InputGroup size="sm" w="60%" >
                                                  <Input
                                                      type="search"
                                                      name="search"
                                                      placeholder="Search users by username, phone number or email"
                                                      onChange={handleInputChange}
                                                  />
                                                  <InputRightElement width="2.5rem">
                                                      <Icon as={BiFilter}/>
                                                  </InputRightElement>
                                              </InputGroup>
                                          </Flex>
                                          <Box  borderBottomWidth="1px">
                                              <Stack direction="row" spacing={1} pt={3}>
                                                  <Menu>
                                                      <MenuButton as={Button} size="xs" rightIcon={<ChevronDownIcon />}>
                                                          Users/page: {pageRequest.pageSize}
                                                      </MenuButton>
                                                      <MenuList>
                                                          <MenuItem value={5} onClick={(e) => {changeSize(e.target as HTMLButtonElement, pageRequest, setPageRequest)}}>5</MenuItem>
                                                          <MenuItem value={10} onClick={(e) => {changeSize(e.target as HTMLButtonElement, pageRequest, setPageRequest)}}>10</MenuItem>
                                                          <MenuItem value={15} onClick={(e) => {changeSize(e.target as HTMLButtonElement, pageRequest, setPageRequest)}}>15</MenuItem>
                                                      </MenuList>
                                                  </Menu>
                                                  <Spacer/>
                                                  <IconButton
                                                      variant="outline"
                                                      aria-label="Previous page"
                                                      isDisabled={!isPrevPage(pageRequest, totalHits)}
                                                      boxSize={6}
                                                      icon={<ChevronLeftIcon />}
                                                      onClick={() => {prevPage(pageRequest, setPageRequest)}}
                                                  />
                                                  <Text pl={1} fontSize="sm">
                                                      {(pageRequest.pageSize * pageRequest.page)}-{Math.min(pageRequest.pageSize * (pageRequest.page +1), totalHits)} of {totalHits}
                                                  </Text>
                                                  <IconButton
                                                      variant="outline"
                                                      isDisabled={!isNextPage(pageRequest, totalHits)}
                                                      aria-label="Next page"
                                                      boxSize={6}
                                                      icon={<ChevronRightIcon />}
                                                      onClick={() => {nextPage(pageRequest, setPageRequest)}}
                                                  />
                                              </Stack>
                                          </Box>
                                          {isLoading ? (
                                            <div className="custom-loader">
                                            </div>
                                            ) : (
                                          <Table variant="simple" size="sm">
                                              <Thead>
                                                  <Tr>
                                                      <Th/>
                                                      <Th color="gostation.500">USERNAME</Th>
                                                      <Th color="gostation.500">FIRST NAME</Th>
                                                      <Th color="gostation.500">LAST NAME</Th>
                                                      <Th color="gostation.500">PHONE NUMBER</Th>
                                                      <Th color="gostation.500">EMAIL</Th>
                                                  </Tr>
                                              </Thead>
                                              <Tbody>
                                                  {usersList && usersList.map((user: User , i: number) => {
                                                      return (
                                                          <Tr key={i}>
                                                              <Td><Checkbox onChange={(e) => addUserList(e.target.checked, user)}/></Td>
                                                              <Td>{user.username}</Td>
                                                              <Td>{user.profile?.firstName}</Td>
                                                              <Td>{user.profile?.lastName}</Td>
                                                              <Td>{user.profile?.phone}</Td>
                                                              <Td>{user.email}</Td>
                                                          </Tr>)
                                                  })}
                                              </Tbody>
                                          </Table>
                                        )}
                                      </ModalBody>
                                      <ModalFooter>
                                          <Button colorScheme="gostation" onClick={addUserAllowed}>
                                              Add
                                          </Button>
                                      </ModalFooter>
                                  </ModalContent>
                              </Modal>
                              {isUserSpecific && <Button ml="auto" leftIcon={<MdFileUpload/>} colorScheme="gostation" bg="gostation.100" variant="solid" size="md" onClick={onOpenList}>UPLOAD LIST</Button>}
                              <Modal isCentered isOpen={isOpenList} onClose={onCloseList} motionPreset="none">
                                  <ModalOverlay />
                                  <ModalContent>
                                      <ModalHeader color="gostation.500">Upload User List CSV</ModalHeader>
                                      <ModalCloseButton />
                                      <ModalBody>
                                          <Stack direction="column">
                                              <Text fontSize="xs">Upload a bulk list of Users</Text>
                                              <CSVReader
                                                  onDrop={handleOnDrop}
                                                  onError={handleOnError}
                                                  addRemoveButton
                                                  onRemoveFile={handleOnRemoveFile}
                                              >
                                                  <Text>Drop CSV file here or click to upload.</Text>
                                              </CSVReader>
                                              <Stack direction="row">
                                                  <Spacer/>
                                                  <Button isDisabled={isImportDisabled} maxW="40%" leftIcon={<MdFileUpload/>} colorScheme="gostation" variant="solid" size="sm" onClick={()=>{addUserCSV()}}>UPLOAD USER LIST</Button>
                                              </Stack>
                                          </Stack>
                                      </ModalBody>
                                  </ModalContent>
                              </Modal>
                              {isUserSpecific && <Button ml="auto" colorScheme="gostation" variant="outline" size="md" onClick={resetData}>RESET</Button>}
                          </HStack>
                          {isUserSpecific && <Box maxWidth="700px" mx="auto" mt="30px">
                              <CustomTable
                                  tableData={usersAllowed}
                                  columnsData={columnsData}
                                  pageSizeDefault={4}
                              />
                          </Box>}
                      </VStack>
                  </HStack>
                  <Divider/>
                  <HStack spacing={6}>
                      <Button as={Link} to="/promoCodes" ml="auto" colorScheme="gostation" variant="outline" size="md">CANCEL</Button>
                      <Button onClick={() => {saveAction(false)}}
                        mr="auto" colorScheme="gostation" variant="solid" size="md"
                        isLoading={isSaving}
                        loadingText="SAVING"
                        spinnerPlacement="start"
                        >SAVE
                      </Button>
                      <Button onClick={() => {saveAction(true)}} mr="auto" colorScheme="primary" variant="solid" size="md">SAVE&SEND</Button>
                  </HStack>
                  <AlertDialog
                      isOpen={isOpenConfirm}
                      //@ts-ignore
                      leastDestructiveRef={cancelRef}
                      onClose={onCloseConfirm}
                  >
                      <AlertDialogOverlay>
                          <AlertDialogContent>
                              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                  Save&Send Promo Code
                              </AlertDialogHeader>

                              <AlertDialogBody>
                                  Are you sure you want to assign the Promo Code to every user and notify them? You can't undo this action afterwards.
                              </AlertDialogBody>

                              <AlertDialogFooter>
                                  {/* @ts-ignore */}
                                  <Button ref={cancelRef} onClick={onCloseConfirm}>
                                      Cancel
                                  </Button>
                                  <Button colorScheme='green' onClick={confirmAction} ml={3}>
                                      Save&Send
                                  </Button>
                              </AlertDialogFooter>
                          </AlertDialogContent>
                      </AlertDialogOverlay>
                  </AlertDialog>
              </VStack>
          </Flex>
          )}
      </Box>
  );
}
